<template>
  <div>
    <b-button
      ref="btn-open-modal-template"
      v-b-modal="id"
      class="d-none"
    />
    <b-modal
      :id="id"
      :ok-title="modalMode === 'create' ? 'Tạo' : 'Sửa'"
      cancel-title="Hủy"
      header-text-variant="primary"
      :title="(modalMode === 'create' ? 'Tạo' : 'Chỉnh sửa') + ' mẫu văn bản'"
      @ok="handleOk"
      @show="fileUpload=null"
    >
      <b-form-group
        label="Tên mẫu văn bản"
        label-for="template_name"
      >
        <b-form-input
          id="template_name"
          v-model="template.name"
          placeholder="Tên mẫu văn bản"
          class="mb-50"
        />
      </b-form-group>
      <b-form-group
        label="Nhóm văn bản"
        label-for="template_type"
      >
        <v-select
          v-model="template.type"
          class="w-100"
          label="label"
          :options="$templateTypeOptions"
          :clearable="true"
          :reduce="val => '' + val.value"
        />
      </b-form-group>
      <b-form-group
        :label="modalMode === 'create' ? 'Chọn file (*.doc, *.docx)' : 'Chọn file thay đổi (nếu có)'"
        label-for="template_new"
      >
        <b-form-file
          v-model="fileUpload"
          :state="Boolean(fileUpload)"
          :placeholder="template.file_name ? getTemplateShortName(template.file_name) : 'Hoặc kéo thả vào đây...'"
          drop-placeholder="Thả vào đây..."
          accept=".doc,.docx"
          @change="$emit('update', template)"
        />
      </b-form-group>
      <span
        v-if="modalErrorText"
        class="text-danger"
      >{{ modalErrorText }}</span>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton, BFormGroup, BFormInput, BFormFile,
} from 'bootstrap-vue'
import {
  onUnmounted, ref,
} from '@vue/composition-api'
import http from '@/global/http'
import store from '@/store'
import templateStoreModule from '@/views/settings/templates/templateStoreModule'
import axiosIns from '@/libs/axios'
import vSelect from 'vue-select'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { $themeConfig } from '../../../../themeConfig'

export default {
  components: {
    BFormGroup,
    BFormInput,
    BFormFile,
    BButton,
    vSelect,
  },
  props: {
    id: {
      type: String,
      default: 'create',
    },
    modalMode: {
      type: String,
      default: 'create',
    },
    modalData: {
      type: Object,
      default: () => {
      },
    },
  },
  watch: {
    modalData(n) {
      this.template = n || {}
    },
  },
  setup(props, { emit }) {
    const toast = useToast()
    const TEMPLATE_STORE_MODULE_NAME = 'template'
    if (!store.hasModule(TEMPLATE_STORE_MODULE_NAME)) store.registerModule(TEMPLATE_STORE_MODULE_NAME, templateStoreModule)
    onUnmounted(() => {
      if (store.hasModule(TEMPLATE_STORE_MODULE_NAME)) store.unregisterModule(TEMPLATE_STORE_MODULE_NAME)
    })

    const template = ref({})

    const fileUpload = ref(null)
    const modalErrorText = ref('')

    const handleOk = async bvModalEvt => {
      if (!template.value.name || (!fileUpload.value && props.modalMode === 'create')) {
        modalErrorText.value = 'Thiếu tên hoặc file cần chọn'
        bvModalEvt.preventDefault()
        return
      }

      const formData = new FormData()

      if (fileUpload.value) {
        formData.append('file', fileUpload.value)
        const resp = await axiosIns.post($themeConfig.app.urlUpload, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        const file = resp.data[0]
        modalErrorText.value = ''
        template.value.file_name = file.filename
        template.value.path = file.origin
        template.value.file_type = file.filename.split('.')
          .pop()
      }
      if (props.modalMode === 'create') {
        http.api(store.dispatch('template/createTemplate', template.value), res => {
          emit('created', res.data)
        })
          .then(() => toast({
            component: ToastificationContent,
            props: {
              title: 'Thành công',
              variant: 'success',
            },
          }))
          .catch(err => toast({
            component: ToastificationContent,
            props: {
              title: err.message,
              variant: 'danger',
            },
          }))
      } else {
        http.api(store.dispatch('template/updateTemplate', template.value), res => {
          emit('updated', res.data)
        })
          .then(() => toast({
            component: ToastificationContent,
            props: {
              title: 'Thành công',
              variant: 'success',
            },
          }))
          .catch(err => toast({
            component: ToastificationContent,
            props: {
              title: err.message,
              variant: 'danger',
            },
          }))
      }
    }

    return {
      fileUpload,
      handleOk,
      template,
      modalErrorText,
    }
  },
}
</script>
